import React from "react";
import { Link } from 'gatsby';
import ContentPage from '../components/ContentPage';
import PageImage from "../components/PageImage";

import image from '../images/undraw_secure_files.svg';

const LegalPage = () => {

    return <ContentPage>
        <h1>Legal</h1>
        <PageImage image={image} alt='Privacy' />
        <h2>Cookies</h2>
        <p>This website does not use cookies at all. That's right, we don't want to harvest your
            information or track your actions.  Our site works just fine without them.</p>
        <p>Because we do not have any cookies, there is nothing for you to accept or reject.</p>

        <h2>Privacy and GDPR</h2>
        <p>This website does not gather any identifiable information about you. We do not hold any
            data that could compromise your privacy.</p>

        <Link to='/' className='btn btn-sm'>Close</Link>

    </ContentPage>
}

export default LegalPage